import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect } from 'react';
import AppTable from '../../../components/tables/AppTable/AppTable';
import { paymentsStoreContext } from '../../../stores/PaymentsStore';

interface Props {}

const columns = [
	{
		title: 'Date',
		dataIndex: 'payDate',
		key: 'payDate',
		sorter: true,
	},
	{
		title: 'Paid',
		dataIndex: 'amount',
		key: 'amount',
		sorter: true,
	},
	{
		title: 'Package Name',
		dataIndex: 'packageName',
		key: 'packageName',
		sorter: true,
	},
];

const PaymentsTable = (props: Props) => {
	const { tableData } = useContext(paymentsStoreContext);

	useEffect(() => {
		tableData.fetch();
	}, []);

	const handleTableChange = (pagination, filters, sorter) => {
		tableData.setPage(pagination.current);
		tableData.setSorter(sorter);
	};

	return (
		<AppTable
			rowKey={(row) => JSON.stringify(row)}
			columns={columns}
			dataSource={tableData.dataSource}
			onChange={handleTableChange}
			pagination={{ ...tableData.pagination }}
		/>
	);
};

export default observer(PaymentsTable);
