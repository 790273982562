import React from 'react';
import { Helmet } from 'react-helmet';
import withPrivateRoute from '../../hocs/withPrivateRoute';
import PaymentsTable from '../../modules/User/Payments/PaymentsTable';
import { Roles } from '../../utils/constants';

interface Props {}

const Payments = (props: Props) => {
	return (
		<>
			<Helmet>
				<title>Payments history</title>
			</Helmet>
			<div className="page">
				<h1 className="page__title">Payments</h1>

				<PaymentsTable />
			</div>
		</>
	);
};

export default withPrivateRoute(Payments, [Roles.user]);
